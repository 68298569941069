import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'

const AboutSection = (props) => {

    const [companyDetails] = useContext(CompanyContext);
    const [aboutSectionData, setAboutSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const aboutSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-about-section-data/' + company_id + '/' + template_id
                    );
                    const result = await aboutSection.json();

                    setAboutSectionData({ "hotel_about_details": result && result.hotel_about_details, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = aboutSectionData && aboutSectionData.section_details

    let display_section_title_des = props && props.data
    return (

        <div>
            <div className="container">
                {section_details && !display_section_title_des && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>

            {aboutSectionData && aboutSectionData.hotel_about_details && aboutSectionData.hotel_about_details.length > 0 &&
                <div className="about-section">
                    <div className={section_details && section_details.background_image &&"parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                        <OwlCarousel
                            className="owl-theme"
                            items={1}
                            loop
                            nav
                        >
                            {aboutSectionData.hotel_about_details.map(function (item, id) {

                                return (
                                    <div className="item" key={id}>
                                        <section className="introduction_area">
                                            <div className="container">
                                                <div className="row introduction_inner">
                                                    <div className="col-md-7">
                                                        <div className="introduction_left_text">
                                                            <div className="intro_title">
                                                                <h1>{item.title}</h1>
                                                            </div>
                                                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                            {!display_section_title_des && <Link className="about_btn_b"
                                                                to={{
                                                                    pathname: config.PATH + 'about',
                                                                }}
                                                            >More About us</Link>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="introduction_img">
                                                            <img src={config.wbImagesEndpoint + '/' + item.image_name} alt={item.about_image_alt} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                )
                            })}

                        </OwlCarousel>
                    </div>
                </div>
            }
        </div>

    )
}

export default AboutSection;