import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json';
import MapBoxComponent from '../MapBoxComponent'

const NearbyPlacesSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [nearbyPlacesSectionData, setNearbyPlacesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const nearbyPlacesSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-nearby-places-section-data/' + company_id + '/' + template_id
                    );
                    const result = await nearbyPlacesSection.json();

                    setNearbyPlacesSectionData({ "nearby_places": result && result.nearby_places, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = nearbyPlacesSectionData && nearbyPlacesSectionData.section_details


    return (
        <div>
            <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>
            <div className={section_details && section_details.background_image && section_details.background_image ? "get_contact_area places-nearby parallax-bg": "get_contact_area places-nearby"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container">
                    {nearbyPlacesSectionData && nearbyPlacesSectionData.nearby_places && nearbyPlacesSectionData.nearby_places.length > 0 ?
                        <div className="row get_contact_inner">
                            <div className="col-md-6">
                                <div className="map_box_inner">
                                    <MapBoxComponent />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right_contact_info">
                                    {nearbyPlacesSectionData.nearby_places[0].places_details && nearbyPlacesSectionData.nearby_places[0].places_details.map(function (item, i) {
                                        return (
                                            <div className="row" style={{ padding: "10px" }} key={i}>
                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                    <div><i className="fa fa-map-marker" aria-hidden="true"></i> {item.place_name}({item.distance} km)</div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-6 mins">
                                                    <div className="pull-right" style={{ marginRight: "20px" }}>{item.time} Mins Drive</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> :
                        <div className="row get_contact_inner">
                            <div className="col-md-12">
                                <div>
                                    <MapBoxComponent />
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
}

export default NearbyPlacesSection;