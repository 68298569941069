import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import config from '../../../src/config.json'
import moment from 'moment';

function FooterFixed() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-fixed-footer-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    if (result.room_details.data) {
                        var all_rooms = { data: {} };
                        var all_hotel_rooms = []
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                        all_rooms.data = all_hotel_rooms;
                    }

                    setwebsiteDetails({ "template_identity": result.template_identity, "hotel_menus": result.hotel_menu_details, "room_details": all_rooms, "hotel_details": result.hotel_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    let hotel_id = websiteDetails && websiteDetails.hotel_menus && websiteDetails.hotel_menus[0].hotel_id
    const [screen_size, setScreensize] = useState(window.innerWidth)
    let room_arr = websiteDetails && websiteDetails.room_details.data
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
    var fromdate = moment();
    var todate = moment(fromdate).add(1, 'days')
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + "" + '|' + true
        let encryptData = btoa(allData);
        iframe = encryptData
    }
    var lowest_price = [];
    if (room_arr) {
        for (var i = 0; i < room_arr.length; i++) {
            lowest_price.push(room_arr[i].rack_price)
        }
        lowest_price.sort();
    }


    return (
        <div>
            {(screen_size < 768) ?
                <div className="container footerfix">
                    <div className="row" style={{ margin: 0 }}>
                        <div className="pull-left footerfix-btn">
                            {websiteDetails && websiteDetails.hotel_details &&
                                <a className="book_now_btn" href={"tel:" + websiteDetails.hotel_details.original[0].mobile}
                                >Call Us</a>
                            }
                        </div>
                        <div className="pull-right footerfix-btn">
                            <Link className="book_now_btn" to={{
                                pathname: config.PATH + 'booknow',
                                search: '?' + (iframe),
                            }}
                            >Book Now</Link>
                        </div>
                    </div>
                </div>

                :
                ''}
        </div>
    )
}
export default FooterFixed;