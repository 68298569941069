import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import FooterFixed from './FooterFixed'
function CustomPage() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [customPageData, setCustomPageData] = useState();


    let custom_page_url;
    custom_page_url = window.location.pathname;
    custom_page_url = custom_page_url.replace(config.PATH, "");

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id

                    const pageData = await fetch(
                        config.apiEndpoint + '/frontview-custom/fetch-frontview-custom-pages-data/' + company_id + '/' + template_id +'/'+ custom_page_url
                    );
                    const result = await pageData.json();
                    
                    setCustomPageData({ "data": result && result.custom_pages_data })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val,custom_page_url])



    useEffect(() => {
        if (customPageData && customPageData.data ) {

            let style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = customPageData && customPageData.data.css;
            let custom_page = document.getElementById("custom_page");
            if(custom_page){
                custom_page.append(style);
            }
            
        }

    }, [customPageData])

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    return (
        <div id="custom_page">            
            {(customPageData === undefined) ? <div className="loader"></div> : <Header />}
            {customPageData && customPageData.data && <div  dangerouslySetInnerHTML={{ __html:customPageData.data.html }}></div>}
            {customPageData && <Footer />}
            {customPageData && <FooterFixed />}

        </div>

    );
}


export default CustomPage;