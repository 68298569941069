import React, { useState, useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { CompanyContext } from "../../global_setting/Store";
import Header from "./Header";
import Footer from "./Footer";
import config from "../../../src/config.json";
import history from "../../history";
import FooterFixed from "./FooterFixed";
import PromotionalBannerSection from "./sections/PromotionalBannerSection";
import NewsletterSection from "./sections/NewsletterSection";

function PlanEvent() {
	// const [websiteDetails] = useContext(WebsiteDetailsContext);

	const [companyDetails] = useContext(CompanyContext);
	const [websiteDetails, setwebsiteDetails] = useState();
	var x = companyDetails && companyDetails.company_id;
	useEffect(() => {
		(async function () {
			try {
				if (companyDetails) {
					var company_id = companyDetails.company_id;
					var template_id = companyDetails.template_id;
					const staticPageData = await fetch(
						config.apiEndpoint +
							"/userwb/get-required-pages-data/" +
							company_id +
							"/" +
							template_id
					);
					const json = await staticPageData.json();
					const result = json.data;

					setwebsiteDetails({
						template_identity: result.template_identity,
						seo_details: result.seo_details,
						promo_news_default: result.promo_news_default,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [x]);

	const websitefont =
		websiteDetails && websiteDetails.template_identity[0].theme_font;
	const [scrolled, setscroll] = useState(false);
	if (scrolled === false) {
		window.scrollTo(0, 0);
	}

	window.addEventListener("scroll", function () {
		setscroll(true);
	});
	return (
		<div>
			{websiteDetails === undefined ? (
				<div className="loader"></div>
			) : (
				<Header />
			)}
			<MetaTags>
				<meta charSet="UTF-8" />
				<title>The Bollywood Hotel</title>
				<meta name="description" content="The Bollywood Hotel" />
			</MetaTags>
			<PromotionalBannerSection />
			<NewsletterSection />
			<section
				className="banner_area"
				style={{
					backgroundImage: "url(" + require("../img/banner1.jpg") + ")",
				}}
			>
				<div className="container">
					<div className="banner_inner_content">
						<h3>Events</h3>
						<ul>
							<li className="active">
								<a onClick={() => history.push(config.PATH + "home")}>Home</a>
							</li>
							<li>
								<a style={{ color: "white" }}>Events</a>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<div className="container">
				<div className="room_service_list">
					<div className="center_w_title">
						<h2>
							Events <span>& Entertainment</span>
						</h2>
						<br />
					</div>
					<div className="row room_service_list_inner services-listing">
						<div className="col-sm-5 col-md-offset-right-1">
							<div className="resot_list">
								<ul>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Weddings
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Corporate and Social Events
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Festival & Carnivals
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Corporates:B2B
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-sm-5 col-md-offset-right-1">
							<div className="resot_list">
								<ul>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Conferences
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Government:Public Affairs
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Exibition & Fairs
										</a>
									</li>
									<li>
										<a>
											<i className="fa fa-caret-right" aria-hidden="true"></i>
											Inuagartion & Launching
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{websiteDetails === undefined ? "" : <Footer />}
			{websiteDetails === undefined ? "" : <FooterFixed />}
		</div>
	);
}

export default PlanEvent;
