import React, { useState, useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { CompanyContext } from "../../global_setting/Store";
import Header from "./Header";
import Footer from "./Footer";
import config from "../../../src/config.json";
import history from "../../history";
import FooterFixed from "./FooterFixed";
import PromotionalBannerSection from "./sections/PromotionalBannerSection";
import NewsletterSection from "./sections/NewsletterSection";

function Sightseeing() {
	const [companyDetails] = useContext(CompanyContext);
	const [websiteDetails, setwebsiteDetails] = useState();
	var x = companyDetails && companyDetails.company_id;
	useEffect(() => {
		(async function () {
			try {
				if (companyDetails) {
					var company_id = companyDetails.company_id;
					var template_id = companyDetails.template_id;
					const staticPageData = await fetch(
						config.apiEndpoint +
							"/userwb/get-required-pages-data/" +
							company_id +
							"/" +
							template_id
					);
					const json = await staticPageData.json();
					const result = json.data;

					setwebsiteDetails({
						template_identity: result.template_identity,
						seo_details: result.seo_details,
						promo_news_default: result.promo_news_default,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [x]);

	const websitefont =
		websiteDetails && websiteDetails.template_identity[0].theme_font;
	const [scrolled, setscroll] = useState(false);
	if (scrolled === false) {
		window.scrollTo(0, 0);
	}

	window.addEventListener("scroll", function () {
		setscroll(true);
	});
	return (
		<div>
			{websiteDetails === undefined ? (
				<div className="loader"></div>
			) : (
				<Header />
			)}
			<MetaTags>
				<meta charSet="UTF-8" />
				<title>The Bollywood Hotel</title>
				<meta name="description" content="The Bollywood Hotel" />
			</MetaTags>
			<PromotionalBannerSection />
			<NewsletterSection />

			<section
				className="banner_area"
				style={{
					backgroundImage: "url(" + require("../img/banner1.jpg") + ")",
				}}
			>
				<div className="container">
					<div className="banner_inner_content">
						<h3>Sightseeing</h3>
						<ul>
							<li className="active">
								<a onClick={() => history.push(config.PATH + "home")}>Home</a>
							</li>
							<li>
								<a style={{ color: "white" }}>Sightseeing</a>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<div className="container">
				<section className="activities_title"></section>
				<section className="activities_area">
					<div className="activities_item_inner">
						<div className="container">
							<div className="row activities_item_row">
								<div className="col-md-6">
									<div className="activities_img">
										<img src={require("../img/lingaraj.jpg")} alt="" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="activities_content">
										<h4>The Lingaraj Temple</h4>
										<p>
											The Lingaraj Temple is an ancient temple with great
											religious importance. Dedicated to Lord Shiva as the name
											suggests, it is visited by huge number of devotees
											throughout the year.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="activities_item_inner">
						<div className="container">
							<div className="row activities_item_row">
								<div className="col-md-6">
									<div className="activities_content left_activities">
										<h4>ISKCON Temple, Bhubaneshwar</h4>
										<p>
											Built by the ISKCON (International Society for Krishna
											Consciousness) in 1991, this temple serves as an
											alternative to Lord Jagannath temple of Puri as that
											temple is restricted to Indians.
										</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="activities_img">
										<img src={require("../img/iskcon-temple.jpg")} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="activities_item_inner">
						<div className="container">
							<div className="row activities_item_row">
								<div className="col-md-6">
									<div className="activities_img">
										<img src={require("../img/hirakud_dam.JPG")} alt="" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="activities_content">
										<h4>Hirakud Dam</h4>
										<p>
											Located at a distance of 15 km from the Sambalpur district
											and around 305 km from Bhubaneshwar, is a small town of
											Hirakud, which houses a major dam established during the
											year 1956. This dam has been a source of tourism in this
											region since many years.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="activities_item_inner">
						<div className="container">
							<div className="row activities_item_row">
								<div className="col-md-6">
									<div className="activities_content left_activities">
										<h4>Parasurameswara Temple</h4>
										<p>
											Constructed in 650 A.D. this temple is a unique specimen
											of Oriyan style of architecture. The most special feature
											of this temple is the presence of one thousand lingas in
											the north-west corner of the complex.
										</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="activities_img">
										<img src={require("../img/paras_temple.jpg")} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			{websiteDetails === undefined ? "" : <FooterFixed />}
			{websiteDetails === undefined ? "" : <Footer />}
		</div>
	);
}

export default Sightseeing;
