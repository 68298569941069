import React, { useState, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Home from "./components/7/Home";
import About from "./components/7/About";
import Rooms from "./components/7/Rooms";
import Contact from "./components/7/Contact";
import Gallery from "./components/7/Gallery";
import Store from "./global_setting/Store";
import RoomDetails from "./components/7/RoomDetails";
import BookNow from "./components/7/BookNow";
import config from "./config";
import history from "./history";
import Services from "./components/7/Services";
import Offers from "./components/7/Offers";
import Blog from "./components/7/Blog";
import Testimonial from "./components/7/Testimonial";
import BlogDetails from "./components/7/BlogDetails";
import Events from "./components/7/Events";
import EventDetails from "./components/7/EventDetails";
import NotFound from "./components/7/NotFound";
import PackageDetails from "./components/7/PackageDetails";
import Packages from "./components/7/Packages";
import Outlets from "./components/7/Outlets";
import Facilities from "./components/7/Facilities";
import Promotions from "./components/7/Promotions";
import ThemeSwitcher from "react-css-vars";
import CustomPage from "./components/7/CustomPage";

import Sightseeing from "./components/7/Sightseeing";
import HotelServices from "./components/7/HotelServices";
import PlanEvent from "./components/7/PlanEvent";
import SpecialOffers from "./components/7/SpecialOffers";
//use of context api use context
function App(props) {
	const [websiteDetails, setwebsiteDetails] = useState();
	const [themeStyle, setThemeStyle] = useState();

	let company_id_val = props.data.company_id;
	useEffect(() => {
		(async function () {
			try {
				if (company_id_val) {
					var company_id = props.data.company_id;
					var template_id = props.data.template_id;
					const dynamicRouteData = await fetch(
						config.apiEndpoint +
							"/wb-frontview/get-frontview-routes-data/" +
							company_id +
							"/" +
							template_id
					);
					const json = await dynamicRouteData.json();
					const result = json.data;
					if (result && result.room_details && result.room_details.data) {
						var all_hotel_rooms = [];
						for (var i = 0; i < result.room_details.data.length; i++) {
							for (var j = 0; j < result.room_details.data[i].length; j++) {
								all_hotel_rooms.push(result.room_details.data[i][j]);
							}
						}
					}

					setwebsiteDetails({
						hotel_packages:
							result && result.hotel_packages && result.hotel_packages,
						hotel_blogs: result && result.hotel_blogs,
						hotel_events: result && result.hotel_events,
						room_details: all_hotel_rooms,
						hotel_menus: result && result.hotel_menu_details,
						custom_page: result && result.custom_page,
					});
					setThemeStyle({
						font: result && result.identity_details.theme_font,
						header_font: result && result.identity_details.header_font,
						title_font: result && result.identity_details.title_font,
						content_font: result && result.identity_details.content_font,
						text_color: result && result.identity_details.text_color,
						theme_color: result && result.identity_details.theme_color,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [company_id_val]);
	return (
		<div>
			<ThemeSwitcher theme={themeStyle}>
				<Store>
					<Router history={history}>
						<Switch>
							<Route exact path={config.PATH + "/"} component={Home} />
							<Route exact path={config.PATH + "home"} component={Home} />
							<Route exact path={config.PATH + "about"} component={About} />
							<Route exact path={config.PATH + "room"} component={Rooms} />
							<Route exact path={config.PATH + "contact"} component={Contact} />
							<Route exact path={config.PATH + "gallery"} component={Gallery} />

							{websiteDetails &&
								websiteDetails.room_details &&
								websiteDetails.room_details.map(function (items, i) {
									let room_type = items.room_type.replace(/[^a-zA-Z ]/g, "");
									room_type = room_type.replace(/ +/g, "_");
									room_type = room_type.toLowerCase();
									return (
										<Route
											exact
											path={config.PATH + "" + room_type}
											component={RoomDetails}
											key={i}
										/>
									);
								})}

							<Route exact path={config.PATH + "booknow"} component={BookNow} />
							<Route
								exact
								path={config.PATH + "services"}
								component={Services}
							/>

							<Route exact path={config.PATH + "offers"} component={Offers} />
							<Route exact path={config.PATH + "blog"} component={Blog} />

							{websiteDetails &&
								websiteDetails.hotel_blogs &&
								websiteDetails.hotel_blogs.map(function (items, i) {
									let blog_title = items.blog_title.replace(
										/[^a-zA-Z0-9 ]/g,
										""
									);
									blog_title = blog_title.replace(/ +/g, "_");
									blog_title = blog_title.toLowerCase();
									return (
										<Route
											exact
											path={config.PATH + "" + blog_title}
											component={BlogDetails}
											key={i}
										/>
									);
								})}
							<Route
								exact
								path={config.PATH + "testimonial"}
								component={Testimonial}
							/>
							<Route exact path={config.PATH + "events"} component={Events} />

							{websiteDetails &&
								websiteDetails.hotel_events &&
								websiteDetails.hotel_events.map(function (items, i) {
									let event_title = items.event_title.replace(
										/[^a-zA-Z0-9 ]/g,
										""
									);
									event_title = event_title.replace(/ +/g, "_");
									event_title = event_title.toLowerCase();
									return (
										<Route
											exact
											path={config.PATH + "" + items.event_title}
											component={EventDetails}
											key={i}
										/>
									);
								})}
							<Route
								exact
								path={config.PATH + "packages"}
								component={Packages}
							/>

							{websiteDetails &&
								websiteDetails.hotel_packages &&
								websiteDetails.hotel_packages.map(function (items, i) {
									let package_title = items.package_title.replace(
										/[^a-zA-Z0-9 ]/g,
										""
									);
									package_title = package_title.replace(/ +/g, "_");
									package_title = package_title.toLowerCase();
									return (
										<Route
											exact
											path={config.PATH + "" + package_title}
											component={PackageDetails}
											key={i}
										/>
									);
								})}
							<Route exact path={config.PATH + "page"} component={Outlets} />
							<Route
								exact
								path={config.PATH + "facilities"}
								component={Facilities}
							/>
							<Route
								exact
								path={config.PATH + "promotions"}
								component={Promotions}
							/>

							{websiteDetails &&
								websiteDetails.custom_page &&
								websiteDetails.custom_page.map(function (items, i) {
									return (
										<Route
											exact
											path={config.PATH + "" + items.page_url}
											component={CustomPage}
											key={i}
										/>
									);
								})}

							<Route
								exact
								path={config.PATH + "trip"}
								component={Sightseeing}
							/>
							<Route
								exact
								path={config.PATH + "hotel_services"}
								component={HotelServices}
							/>
							<Route
								exact
								path={config.PATH + "special_offers"}
								component={SpecialOffers}
							/>
							<Route
								exact
								path={config.PATH + "hotel_event"}
								component={PlanEvent}
							/>

							{websiteDetails && <Route exact path="*" component={NotFound} />}
						</Switch>
					</Router>
				</Store>
			</ThemeSwitcher>
		</div>
	);
}

export default App;
