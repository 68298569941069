import React, { useState, useContext,useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../config.json'


function VideoSlider(){
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                   
                    setwebsiteDetails({ "slider_details": result.slider_details,"video_details": result.video_details,"template_identity": result.template_identity });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    let websiteUrl=websiteDetails && websiteDetails.video_details && websiteDetails.video_details.video_url
    websiteUrl = websiteUrl && websiteUrl.slice(websiteUrl.indexOf("."),websiteUrl.length)
    websiteUrl = websiteUrl && websiteUrl.slice(websiteUrl.indexOf("/")+1,websiteUrl.length)

    return(
        <div>
                <div className="video-date-picker-wrapper"> 
                    {/* {(websiteDetails && websiteDetails.video_details.video_name !== undefined) ? */}
                        <div className="video-wrapper">
                            <video poster={require('../main-slider/7.jpg')} playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                    
                                {/* <source src={config.wbImagesEndpoint + '/' + websiteDetails.video_details.video_name} type="video/mp4" />
                                <source src={config.wbImagesEndpoint + '/' + websiteDetails.video_details.video_name} type="video/ogg" /> */}
                            <source src={require('../video.mp4')} type="video/mp4"/>
                            </video>
                        </div>
                        {/* :
                        <div className="video-container-youtube">
                            <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/"+ websiteUrl + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                        </div>
                    } */}
                </div>
        </div>
    )
}
 export default VideoSlider;