import React, { useState, useEffect, useContext } from "react";
import { CompanyContext } from "../../../global_setting/Store";
import config from "../../../../src/config.json";
import { Link } from "react-router-dom";

const RoomSection = () => {
	const [companyDetails] = useContext(CompanyContext);
	const [roomSectionData, setRoomSectionData] = useState();
	let company_id_val = companyDetails && companyDetails.company_id;

	useEffect(() => {
		(async function () {
			try {
				if (company_id_val) {
					let company_id = companyDetails.company_id;
					let template_id = companyDetails.template_id;
					let all_hotel_rooms = [];
					let room_data = [];
					const roomSectionData = await fetch(
						config.apiEndpoint +
							"/wb-frontview/get-frontview-room-section-data/" +
							company_id +
							"/" +
							template_id
					);
					const result = await roomSectionData.json();

					if (result && result.room_details.data) {
						for (var i = 0; i < result.room_details.data.length; i++) {
							for (var j = 0; j < result.room_details.data[i].length; j++) {
								all_hotel_rooms.push(result.room_details.data[i][j]);
							}
						}
					}

					if (result && result.default_hotel) {
						for (let i = 0; i < all_hotel_rooms.length; i++) {
							if (all_hotel_rooms[i].hotel_id === result.default_hotel) {
								room_data.push(all_hotel_rooms[i]);
							}
						}
					} else {
						for (let i = 0; i < all_hotel_rooms.length; i++) {
							if (
								all_hotel_rooms[i].hotel_id ===
								result.all_hotels_data[0].hotel_id
							) {
								room_data.push(all_hotel_rooms[i]);
							}
						}
					}

					setRoomSectionData({
						room_details: room_data,
						section_details: result && result.section_details,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [company_id_val]);

	let websitefont = "";
	let section_details = roomSectionData && roomSectionData.section_details;
	return (
		<div>
			<div className="container">
				{section_details && (
					<div className="center_title">
						<h2>{section_details.section_title}</h2>
						<p
							dangerouslySetInnerHTML={{
								__html: section_details.section_description,
							}}
						></p>
					</div>
				)}
			</div>

			<div
				className={
					section_details && section_details.background_image && "parallax-bg"
				}
				style={
					section_details && {
						backgroundImage:
							"url(" +
							config.wbImagesEndpoint +
							"/" +
							section_details.background_image +
							")",
					}
				}
			>
				<section className="explor_room_area explore_room_list">
					{roomSectionData &&
						roomSectionData.room_details &&
						roomSectionData.room_details.length > 0 && (
							<div className="container">
								<div className="row explor_room_item_inner">
									{roomSectionData.room_details.map(function (item, id) {
										if (id < 3) {
											var send_room_type = item.room_type.replace(
												/[^a-zA-Z ]/g,
												""
											);
											send_room_type = send_room_type.replace(/ +/g, "_");
											send_room_type = send_room_type.toLowerCase();
											return (
												<div className="col-md-4 col-sm-6" key={id}>
													<div className="explor_item">
														<Link
															className="room_image"
															to={{
																pathname: config.PATH + "" + send_room_type,
															}}
														>
															<img
																src={
																	config.imageEndpoint +
																	"/" +
																	item.room_image[0]
																}
																alt=""
															/>
														</Link>
														<div className="explor_text">
															<Link
																to={{
																	pathname: config.PATH + "" + send_room_type,
																}}
															>
																<h4>{item.room_type}</h4>
															</Link>

															<div className="explor_footer">
																<div className="pull-left">
																	<h3>
																		₹{item.rack_price} <span>/ Night</span>
																	</h3>
																</div>
																<div className="pull-right">
																	<Link
																		className="book_now_btn"
																		to={{
																			pathname:
																				config.PATH + "" + send_room_type,
																		}}
																	>
																		View details
																	</Link>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										}
									})}
								</div>
							</div>
						)}
				</section>
			</div>
		</div>
	);
};

export default RoomSection;
