import React, { useState, useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { CompanyContext } from "../../global_setting/Store";
import Header from "./Header";
import Footer from "./Footer";
import config from "../../../src/config.json";
import history from "../../history";
import FooterFixed from "./FooterFixed";
import CustomFooterSection from "./sections/CustomFooterSection";
import PromotionalBannerSection from "./sections/PromotionalBannerSection";
import NewsletterSection from "./sections/NewsletterSection";

function HotelServices() {
	// const [websiteDetails] = useContext(WebsiteDetailsContext);

	const [companyDetails] = useContext(CompanyContext);
	const [websiteDetails, setwebsiteDetails] = useState();
	var x = companyDetails && companyDetails.company_id;
	useEffect(() => {
		(async function () {
			try {
				if (companyDetails) {
					var company_id = companyDetails.company_id;
					var template_id = companyDetails.template_id;
					const staticPageData = await fetch(
						config.apiEndpoint +
							"/userwb/get-required-pages-data/" +
							company_id +
							"/" +
							template_id
					);
					const json = await staticPageData.json();
					const result = json.data;

					setwebsiteDetails({
						template_identity: result.template_identity,
						seo_details: result.seo_details,
						promo_news_default: result.promo_news_default,
						hotel_listed_services: result.hotel_listed_services,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [x]);

	const websitefont =
		websiteDetails && websiteDetails.template_identity[0].theme_font;
	const [scrolled, setscroll] = useState(false);
	if (scrolled === false) {
		window.scrollTo(0, 0);
	}

	window.addEventListener("scroll", function () {
		setscroll(true);
	});

	let services =
		websiteDetails &&
		websiteDetails.hotel_listed_services &&
		websiteDetails.hotel_listed_services[0].hotel_services;

	return (
		<div>
			{websiteDetails === undefined ? (
				<div className="loader"></div>
			) : (
				<Header />
			)}
			<MetaTags>
				<meta charSet="UTF-8" />
				<title>The Bollywood Hotel</title>
				<meta name="description" content="The Bollywood Hotel" />
			</MetaTags>

			<PromotionalBannerSection />
			<NewsletterSection />

			<section
				className="banner_area"
				style={{
					backgroundImage: "url(" + require("../img/banner1.jpg") + ")",
				}}
			>
				<div className="container">
					<div className="banner_inner_content">
						<h3>Services</h3>
						<ul>
							<li className="active">
								<a onClick={() => history.push(config.PATH + "home")}>Home</a>
							</li>
							<li>
								<a style={{ color: "white" }}>Services</a>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<div className="container">
				<div className="room_service_list">
					<div className="center_w_title">
						<h2>
							Our <span>Services</span>
						</h2>
						<br />
					</div>
					<div className="row room_service_list_inner services-listing">
						<div className="col-sm-5 col-md-offset-right-1">
							<div className="resot_list">
								<ul>
									{websiteDetails &&
										websiteDetails.hotel_listed_services &&
										websiteDetails.hotel_listed_services[0].hotel_services &&
										services.map(function (items, id) {
											if (id % 2 !== 0) {
												return (
													<li key={id}>
														<a>
															<i
																className="fa fa-caret-right"
																aria-hidden="true"
															></i>
															{items}
														</a>
													</li>
												);
											}
										})}
									{/* <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>In room Dining</a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Takeaway services</a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Fun zone & Selfie Arena </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Laundry Services </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Inhouse Banquets </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Social Events </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Package provided for visit to any places in India </a></li> */}
								</ul>
							</div>
						</div>
						<div className="col-sm-5 col-md-offset-right-1">
							<div className="resot_list">
								<ul>
									{websiteDetails &&
										websiteDetails.hotel_listed_services &&
										websiteDetails.hotel_listed_services[0].hotel_services &&
										services.map(function (items, id) {
											if (id % 2 === 0) {
												return (
													<li key={id}>
														<a>
															<i
																className="fa fa-caret-right"
																aria-hidden="true"
															></i>
															{items}
														</a>
													</li>
												);
											}
										})}

									{/* <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Business Center</a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Pick up & Drop from Airport or Railway Station</a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Free Wifi  </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Gymnasium </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Outdoor Catering </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Corporate catering Services </a></li>
                                <li><a ><i className="fa fa-caret-right" aria-hidden="true"></i>Travels Arrangement done for sightseeing </a></li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* {websiteDetails && <CustomFooterSection />} */}

			{websiteDetails === undefined ? "" : <FooterFixed />}
			{websiteDetails === undefined ? "" : <Footer />}
		</div>
	);
}

export default HotelServices;
