import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import moment from 'moment';
import history from '../../history';

function Header() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-header-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    if (result && result.header_menu_details.length > 5) {
                        let header_menu = result.header_menu_details;
                        var extra_header_menu = header_menu.splice(6, 6);

                        extra_header_menu.forEach(function (item, i) {
                            item["menu"] = "active";
                        })
                    } else {
                        extra_header_menu = [];
                    }

                    var all_menu_details = {
                        "header": {
                            "header_menus": result && result.header_menu_details,
                            "other_menus": extra_header_menu
                        }
                    }
                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    const websitefont = websiteDetails && websiteDetails.template_identity[0] && websiteDetails.template_identity[0].theme_font

    let url = window.location.href;
    var splitUrl = url.split('/');
    var activemenu = splitUrl[splitUrl.length - 1]

    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]
    
    let fromdate = moment();
    let todate = moment(fromdate).add(1, 'days');
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();

    let hotel_id = websiteDetails && websiteDetails.hotel_menus && websiteDetails.hotel_menus[0].hotel_id

    var iframe
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + "" + '|' + true
        let encryptData = btoa(allData);
        iframe = encryptData

    }

    //function use to redirect the page
    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if(page_url.includes("www")){
                page_url = page_url.trimStart()
                window.open("http://" + page_url)
                }

                else{
                    history.push(config.PATH + page_url);
                }
            }
        }
    }

    return (
        <div>            
            <header className="main_header_area">
                <div className="header_top">
                    <div className="container">
                        <div className="header_top_inner">
                            <div className="pull-left">
                                {websiteDetails && hotelDetails &&
                                <a href={"tel:" + hotelDetails.mobile}><i className="fa fa-phone"></i> {websiteDetails && hotelDetails && hotelDetails.mobile}</a>}

                                {websiteDetails && hotelDetails &&
                                <a href={"mailto:" + hotelDetails.email_id}><i className="fa fa-envelope-o"></i>{websiteDetails && hotelDetails && hotelDetails.email_id}</a>}

                            </div>
                            <div className="pull-right">
                                <ul className="header_social">
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? hotelDetails.facebook_link : "#"}><i className="fa fa-facebook"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? hotelDetails.twitter_link : "#"}><i className="fa fa-twitter"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? hotelDetails.instagram_link : "#"}><i className="fa fa-instagram"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.linkedin_link) ? hotelDetails.linkedin_link : "#"}><i className="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu">
                    <nav className="navbar navbar-default">
                        <div className="container">

                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                {websiteDetails && websiteDetails.template_identity[0] &&
                                    <a className="navbar-brand logo-wrap" onClick={() => history.push(config.PATH + 'home')}>
                                        <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt="" />
                                        <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt="" />
                                    </a>
                                }
                            </div>

                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">

                                    {websiteDetails && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                        if(item.page_id===1){
                                            item.page=item.page_url;
                                        }
                                        return (
                                            <li key={i}
                                                className={(item.page === activemenu ? 'active' : '')} >
                                                <a onClick={() => redirectTo(item)}
                                                    >{item.menu_name}
                                                </a>
                                            </li>
                                        )
                                    })}
                                   


                                    <li className="submenu dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                                        <ul className="dropdown-menu">
                                            {websiteDetails && websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                                if(item.page_id===1){
                                                    item.page=item.page_url;
                                                }
                                                return (
                                                    <li key={i}>
                                                        <a onClick={() => redirectTo(item)}
                                                            >{item.menu_name}</a>
                                                    </li>

                                                )
                                            })}
                                            
                                            
                                        </ul>
                                    </li>

                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                    <li className="book_btn">
                                        <Link className="book_now_btn"
                                            to={{
                                                pathname: config.PATH + 'booknow',
                                                search: '?' + (iframe),
                                            }}
                                        >Book Now</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        </div>
    )
}

export default Header